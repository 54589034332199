/**
 * login 처리~
 */
export default (to, from, next) => {
  if (
    localStorage.getItem("userInfo") != null &&
    localStorage.getItem("userInfo").length > 0
  ) {
    next();
  } else {
    // 자동로그인 처리(로그인 안함)
    localStorage.removeItem("userInfo");
    next('/app/sessions/admin');
    // localStorage.removeItem("userInfo");
    // next("/app/sessions/sign-in-two");
  }
};